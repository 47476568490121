.navbar-toggler {
  border: none;
  background: transparent !important;

  &:focus {
    outline: none;
    background: transparent !important;
  }

  .icon-bar {
    background-color: #000;
    transform: rotate(0deg) translate(0px, 0px);
    transition: ease all .2s;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;

    + .icon-bar {
      margin-top: 4px;
    }
  }
  .icon-bar:nth-child(2) {
  width: 16px;
  transition: ease all .2s;
  }
  &:hover > .icon-bar:nth-child(2), &:active > .icon-bar:nth-child(2) {
    width: 22px;
    transition: ease all .2s;
  }

  &:not(.collapsed) .icon-bar {
    &:nth-child(1) {
      transform: rotate(45deg) translate(5px, 4px);
      transition: ease all .2s;
    }

    &:nth-child(2) {
      opacity: 0;
      transition: ease all .2s;
    }

    &:nth-child(3) {
      transform: rotate(-45deg) translate(4px, -4px);
      transition: ease all .2s;
    }
  }
}