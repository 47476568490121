
//Bootstrap styles 
@import "node_modules/bootstrap/scss/bootstrap-reboot";
@import "node_modules/bootstrap/scss/bootstrap-grid";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "settings";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/badge";
@import "node_modules/bootstrap/scss/jumbotron";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/progress";
@import "node_modules/bootstrap/scss/media";
@import "node_modules/bootstrap/scss/list-group";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/tooltip";
@import "node_modules/bootstrap/scss/popover";
@import "node_modules/bootstrap/scss/carousel";
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/print";


@import "node_modules/aos/src/sass/aos";

// font awesome


$fa-font-path:"../fonts" ;
@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid";


//Custom mixins
@import "mixins/mixins";

//Component specific styles 
@import "components/nav";

//Form specific styles
@import "forms/buttons";


html,body, main{
    position: relative;
    margin: 0;
    @include media-breakpoint-up(md) {height:100%; }
}
body{
    padding-top: 90px;
    background-color: #9D191D;
    /*
    background-image: url(../../assets/img/bg.jpg);
*/
   
}
.text-dark{
    color: #7b7b7b;
}
.bg-primary{
    background: rgb(236,29,36);
background: linear-gradient(180deg, rgba(236,29,36,1) 0%, rgba(246,136,31,1) 100%);
}
.spacer{
    padding-top: $spacer;
}
.loading{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1050;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
/*
    background: rgb(237,23,31);
    background: linear-gradient(90deg, rgba(237,23,31,1) 40%, rgba(215,14,62,1) 60%);
*/
}
section{
}
.img-cover{
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}


.page-title{
    display: flex;
    align-items: center;
    padding: 20px;
    color: #fff;
    min-height: 88px;
    background: rgb(237,23,31);
    background: linear-gradient(90deg, rgba(237,23,31,1) 40%, rgba(215,14,62,1) 60%);
}
.splash{
    background-image: url(../../assets/img/splash.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(237,23,31);
     @media (orientation: landscape) {
    background-image: url(../../assets/img/splash_desktop.jpg);
	}
}
.corner-box{
    position: relative;
    padding: 20px;
    max-width: 200px;
    margin: auto;
    .corner{
        position: absolute;
        width: 20px;
        height: 20px;
    }
    .corner-lt{
        border-top: 3px solid #fff;
        border-left: 3px solid #fff;
        left: 0;
        top: 0;
    }
    .corner-rt{
        border-top: 3px solid #fff;
        border-right: 3px solid #fff;
        top: 0;
        right: 0;
    }
    .corner-lb{
        border-bottom: 3px solid #fff;
        border-left: 3px solid #fff;
        left: 0;
        bottom: 0;
    }
    .corner-rb{
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
        bottom: 0;
        right: 0;
    }
}
.btn-primary{
    background: linear-gradient(90deg, rgba(237,23,31,1) 0%, rgba(215,14,62,1) 100%);
}
.bg-gradient{
    background: linear-gradient(90deg, rgba(237,23,31,1) 0%, rgba(215,14,62,1) 100%);
}
.text-shadow{
	text-shadow: 	0px 0px 2px #FFF,
					0px 0px 2px #FFF;
}

.fire1 {
    animation: scale-fade-out 2s linear infinite;
}
.fire2 {
    animation: scale-fade-out 2s linear infinite;
}

@keyframes scale-fade-out {
    0% {
        transform: scale(0);
        opacity: 0;
    }

	80% {
        opacity: 1;
    }

    100% {
        transform: scale(0.8);
        opacity: 0;
    }
}
.balloon {
  animation: MoveUpDown 3s linear infinite;
  position: absolute;
  left: 0;
  bottom: 0;
}

@keyframes MoveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}